/* this file exists only to use self-hosted font */
@font-face {
  font-family: 'Gen Vision Font';
  src: url('./fonts/customFont.woff') format('woff');
  src: url('./fonts/customFont.ttf') format('truetype');
}
#root {
  height: 100%;
  width: 100%;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

.arrow {
  margin-bottom: 5px;
  transform: translate(-50%, -50%) rotateZ(180deg);
}
.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #fff;
  border-right: 5px solid #fff;
  transform: rotate(0deg);
  margin: -10px;
  animation: animate 1s infinite;
  cursor: pointer;
}
.arrow span:nth-child(2) {
  cursor: pointer;
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  cursor: pointer;
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-10px, -10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
}
